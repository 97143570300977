//
// alerts
// Extended from Bootstrap
//

//
// Bootstrap Overrides =====================================
//

// Allow for a text-decoration since links are the same color as the alert text.

.alert-link {
  text-decoration: $alert-link-text-decoration;
}

// Color variants
//
// Using Bootstrap's core alert-variant mixin to generate solid background color + yiq colorized text (and making close/links match those colors)

@each $color, $value in $theme-colors {
  .alert-#{$color} {
    --alert-bg: theme-color('alert', $color);
    --alert-border: theme-color('alert', $color);
    --alert-contrast: theme-color('alert', $color);

    hr {
      background-color: darken(shift-color($value, $alert-border-scale), 5%);
    }

    .btn-close {
      padding: calc(#{$alert-padding-y} + #{$btn-close-padding-y})
        $alert-padding-x;
      background-image: escape-svg(
        url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='#{color-contrast(shift-color($value, $alert-bg-scale))}'><path d='M.293.293a1 1 0 011.414 0L8 6.586 14.293.293a1 1 0 111.414 1.414L9.414 8l6.293 6.293a1 1 0 01-1.414 1.414L8 9.414l-6.293 6.293a1 1 0 01-1.414-1.414L6.586 8 .293 1.707a1 1 0 010-1.414z'/></svg>")
      );
    }

    .alert-link {
      color: color-contrast(shift-color($value, $alert-bg-scale));
    }
  }
}
