// 
// type.scss
// Extended from Bootstrap
//

// 
// Bootstrap Overrides =====================================
//

h1, .h1 {
  margin-bottom: $headings-margin-bottom;
  font-size: 1.5rem;

  @include media-breakpoint-up(md) {
    font-size: $h1-font-size;
  }
}

h2, .h2 {
  margin-bottom: $headings-margin-bottom;
}

h3, .h3 {
  margin-bottom: ($headings-margin-bottom * .75);
}

h4, .h4 {
  margin-bottom: ($headings-margin-bottom * .5);
}

h5, .h5 {
  margin-bottom: ($headings-margin-bottom * .5);
}

h6, .h6 {
  margin-bottom: ($headings-margin-bottom * .5);
}


// Links

h1, .h1, h2, .h2, h3, .h3, h4, .h4, h5, .h5, h6, .h6 {

  > a {
    color: inherit;
  }
}

// Type display classes

.display-1,
.display-2,
.display-3,
.display-4 {
  letter-spacing: $display-letter-spacing;
}

// Headings

h1, .h1, h2, .h2, h3, .h3, h4, .h4, h5, .h5, h6, .h6 {
  letter-spacing: $headings-letter-spacing;
}

h6.text-uppercase {
  letter-spacing: .08em;
}

// Bold, strong

b, strong {
  font-weight: $font-weight-bold;
}


// Links, buttons
//
// Removes focus outline

a, button {

  &:focus {
    outline: none !important;
  }
}


// 
// Theme =====================================
//

// Include A

@font-face {
  font-family: 'Avenir Next Regular';
  src: url('#{$path-to-fonts}/avenir/avenir-next-regular.otf');

}

@font-face {
  font-family: 'Avenir Next Italic';
  src: url('#{$path-to-fonts}/avenir/avenir-next-italic.otf');

}

@font-face {
  font-family: 'Avenir Next Demi';
  src: url('#{$path-to-fonts}/avenir/avenir-next-demi.otf');

}

@font-face {
  font-family: 'Avenir Next Demi Italic';
  src: url('#{$path-to-fonts}/avenir/avenir-next-demi-italic.otf');

}

@font-face {
  font-family: 'Avenir Next Condensed';
  src: url('#{$path-to-fonts}/avenir/avenir-next-condensed.otf');

}


// Include Feather icons

@font-face {
  font-family: 'Feather';
  src: url('#{$path-to-fonts}/feather/Feather.ttf?sdxovp') format('truetype'), url('#{$path-to-fonts}/feather/Feather.woff?sdxovp') format('woff'), url('#{$path-to-fonts}/feather/Feather.svg?sdxovp#Feather') format('svg');
  font-weight: 400;
  font-style: normal;
}
