//
// reboot.scss
// Extended from Bootstrap
//

html {
  height: 100%;
}

body {
  min-height: 100%;
}

a {
  text-decoration: none !important;
}

// Lists

ul,
ol {
  padding-left: 2.5rem;
}

//
// Remove the cancel buttons in Chrome and Safari on macOS.
//

[type="search"]::-webkit-search-cancel-button {
  -webkit-appearance: none;
}
