//
// user-variables.scss
// Use this to overwrite Bootstrap and theme variables
//

// Example of a variable override to change theme's background color
// Remove the "//" to comment it in and see it in action!
// $body-bg: #12263F;

.modal-border {
  border-radius: inherit;
  border: 1px solid rgba(211, 211, 211, 1);
}

@media screen and (max-width: 576px) {
  .xs-bottom__margin {
    margin-bottom: 0.75rem;
  }
}

.bg-intuita_main {
  background-color: var(--intuita-main);
}

.nav-tabs .nav-link.active {
  border-color: var(--intuita-main) !important;
}

.sharedData-bg {
  background-color: #a5bfaf66 !important;
}

.datasetData-bg {
  background-color: #e1e1e166 !important;
}

.templateData-bg {
  background-color: #d5bfab66 !important;
}

.newData-bg {
  background-color: #2f80ed66 !important;
}

.defaultData-bg {
  background-color: #ffffff !important;
}

.new-list-items-bg {
  background-color: #a5bfaf66 !important;
}

.list-items-to-delete-bg {
  background-color: #bfa5a566 !important;
}

.existing-list-items-bg {
  background-color: #e1e1e166 !important;
}
