.user-avatar_topnav {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 2.5rem;
  width: 2.5rem;
  border-radius: 50%;
  background-color: var(--intuita-main);
  color: white;
  font-weight: bold;
}

.user-avatar_topnav-sm {
  height: 2.5rem;
  width: 2.5rem;
  display: flex;
  align-content: center;
  flex-wrap: wrap;
  justify-content: center;
  background-color: var(--intuita-main);
  color: #fff;
  border-radius: 50%;
  font-weight: 700;
}

.dd-setup {
  position: relative;
  display: inline-block;
  width: 100%;
}

.dd-setup_toggle {
  display: flex;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;
  height: 65px;
}

.dd-setup_arrow {
  margin-left: 0.5rem;
  font-size: 0.8rem;
}

.dd-setup_menu {
  position: absolute;
  top: 100%;
  left: 0;
  z-index: 1;
  padding: 0.5rem;
  background-color: #232751;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.1);
  min-height: fit-content;
  max-height: 200px;
  overflow-y: auto;
  display: flex;
  flex-direction: column;
  width: max-content;
}

.dd-setup_item {
  padding: 0.25rem 0.5rem;
  color: white;
  cursor: pointer;
}

.dd-setup_label {
  font-style: normal;
  font-size: 0.875rem;
  line-height: 1.313rem;
  color: #222222;
}

.dd-setup_item:hover {
  background-color: #f1f1f1;
  color: black;
}

.dd {
  position: relative;
  display: inline-block;
  width: 100%;
}

.dd-toggle {
  display: flex;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;
}

.dd-arrow {
  margin-left: 0.5rem;
  font-size: 0.8rem;
}

.dd-menu {
  position: absolute;
  top: 100%;
  left: 0;
  z-index: 999;
  padding: 0.5rem;
  background-color: #fff;
  border: 1px solid #ccc;
  border-radius: 4px;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.1);
  min-height: fit-content;
  max-height: 250px;
  overflow-y: auto;
  display: flex;
  flex-direction: column;
  width: max-content;
}

.dd-item {
  padding: 0.25rem 0.5rem;
  cursor: pointer;
}

.dd-label {
  font-style: normal;
  font-size: 0.875rem;
  line-height: 1.313rem;
  color: #222222;
}

.dd-item:hover {
  background-color: #f1f1f1;
}

.topnav-wrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: rgba(34, 34, 34, 1);
  padding: 0 0.5rem;
  height: 65px;
}

.topnav-left {
  display: flex;
  align-items: center;
  gap: 2rem;
}

.topnav-right {
  display: flex;
  align-items: center;
  gap: 1rem;
}

.action-icons__wrapper {
  display: flex;
  align-items: center;
  gap: 1rem;
}

.topnav-vertical_divider {
  height: 50px;
  width: 1px;
  background-color: #fff;
}

.topnav-navigation {
  display: flex;
  align-items: center;
  gap: 1rem;
}

.topnav-navigation_item {
  color: #fff;
  padding: 0.5rem;
  font-weight: 400;
}

.topnav-navigation_item-selected {
  color: #fff;
  padding: 0.5rem;
  border-bottom: 2px solid var(--intuita-main);
  font-weight: bold;
}

.topnav-navigation_item:hover {
  color: #fff;
}

.dd_profile {
  position: relative;
  display: inline-block;
  width: 100%;
}

.dd-toggle_profile {
  display: flex;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;
}

.dd-arrow_profile {
  margin-left: 0.5rem;
  font-size: 0.8rem;
}

.dd-menu_profile {
  position: absolute;
  top: 100%;
  right: 0;
  z-index: 1;
  padding: 1rem 1.75rem;
  background-color: #fff;
  border: 1px solid #ccc;
  border-radius: 4px;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.1);
  display: flex;
  flex-direction: column;
  width: max-content;
}

.dd-item_profile {
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  cursor: pointer;
  display: flex;
  align-items: center;
  gap: 0.5rem;
}

.dd-label_profile {
  font-style: normal;
  font-size: 0.875rem;
  line-height: 1.313rem;
  color: #222222;
}

.notification-item:hover {
  background-color: #f1f1f1;
}
