//
// list-group.scss
// Extended from Bootstrap
//

//
// Bootstrap Overrides =====================================
//

// Contextual variants
//
// Changing the Bootstrap color modifier classes to be full opacity background with yiq calculated font color

@each $color, $value in $theme-colors {
  .list-group-item-#{$color} {
    background-color: $value;
    color: color-yiq($value); // Adjust this line as needed
  }
}

// List group sizing

.list-group-lg .list-group-item {
  padding-top: $list-group-item-padding-y-lg;
  padding-bottom: $list-group-item-padding-y-lg;
}

// List group flush

.list-group-flush > .list-group-item {
  padding-left: 0;
  padding-right: 0;
}

.list-group-flush:not(:last-child) > .list-group-item:last-child {
  border-bottom-width: $list-group-border-width;
}

// List group focus

.list-group-focus .list-group-item:focus .text-focus {
  color: $link-color !important;
}

//
// Theme ===================================
//

// Activity

.list-group-activity .list-group-item {
  border: 0;
}

.list-group-activity .list-group-item:not(:last-child)::before {
  content: '';
  position: absolute;
  top: $list-group-item-padding-y;
  left: $avatar-size-sm * 0.5;
  height: 100%;
  border-left: $border-width solid $border-color;
}
